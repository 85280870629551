/* Botão flutuante fixo */
.fixed-cart-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--blue);
    color: white;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    z-index: 900;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }

  .cart-badge {
    position: absolute; /* Bolinha posicionada em relação ao botão */
    top: -5px;
    right: 0px;
    background-color: var(--orange);
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    z-index: 1000;
  }

  /* Menu lateral */
  .fixed-cart-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blue);
    z-index: 999;
    transform: translateX(100%); /* Começa fora da tela à direita */
    opacity: 0; /* Começa invisível */
    transition: transform 0.5s ease, opacity 0.5s ease; /* Transição suave ao abrir/fechar */
    overflow-y: auto;
    border-left: 0.5px solid white;
  }
  
  .fixed-cart-menu.open {
    transform: translateX(0); /* Move o menu para dentro da tela */
    opacity: 1; /* Torna o menu visível */
  }
  
  .fixed-cart-menu ul {
    list-style-type: none;
    padding: 10px;
    margin: 0;
  }
  
  .fixed-cart-menu li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .fixed-cart-menu li:hover {
    color: white;
    cursor: default;
  }
  
  .fixed-cart-menu li:last-child {
    border-bottom: none;
  }
  
  .fixed-cart-menu li a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    display: block;
  }
  
  @media (min-width: 768px) {
    .fixed-cart-menu {
      width: 550px;
    }
  }

  @media (max-width: 750px) {
    .fixed-cart-btn {
      width: 80px;
      height: 80px;
    }
  }
  