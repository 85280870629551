.button-home {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 32px;
    padding: 15px 30px;
    border-radius: 12px;
}

@media (max-width: 768px) {
    .button-home {
        font-size: 21px;
        gap: 8px;
    }
}