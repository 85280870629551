.custom-table .ant-table-tbody > tr > td {
    background-color: #f7f7f7; // Cor de fundo para as linhas
}

.custom-table .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: #ebebeb; // Cor de fundo para as linhas ímpares (tom mais claro de cinza)
}

.custom-table .ant-table-tbody > tr.selected-row > td {
    background-color: #28325D !important; // Azul escuro para linha selecionada
    color: white; // Cor do texto para linha selecionada
}

.custom-table .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: blue($color: #000000); // Azul escuro (ou outra cor que contraste com o check)
    border-color: #28325D; // Mesma cor da linha selecionada
}

.custom-table .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white; // A cor do checkmark em si (deve contrastar com o fundo)
}

.ant-table-thead > tr > th {
    background-color: var(--blue) !important; /* Cor de fundo */
    color: white !important; /* Cor do texto */
}

.ant-input {
    font-size: 20px;
    border-radius: 100px;
    padding: 6px 20px;
}

.anticon.anticon-search {
    padding: 15px !important;
    background-color: #28325D !important;
    color: white;
    border-radius: 0px 25px 25px 0px;
    margin-left: 14px;
}

.ant-table-cell {
    font-size: 20px; // tamanho da fonte da tabela
}

.activated-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 65px;
    background-color: #e6e6e6;
    border-radius: 15px 15px 0px 0px;
    border-bottom: solid 2px var(--orange);
}

.disabled-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 65px;
    background-color: white;
    border-bottom: solid 2px var(--blue);
}

.ant-table-cell.ant-table-selection-column .ant-table-selection {
    display: none;
}

.ant-table-body {
    border-radius: 0px 0px 10px 10px !important;
}