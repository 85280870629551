ul {
    display: flex;
    gap: 60px;
    list-style-type: none; 
    padding: 0; 
    margin: 0; 

    li:hover {
        cursor: pointer;
        color: orange;
    }
}

.social {
    cursor: pointer;
}

