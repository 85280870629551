/* src/styles/styles.scss */
.hamburger {
    position: relative;
    width: 30px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
  
.hamburger-line {
    width: 100%;
    height: 4px;
    background-color: white; /* Define a cor branca para as linhas */
    transition: all 0.3s ease;
}
  
.hamburger-line.open:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
}
  
.hamburger-line.open:nth-child(2) {
    opacity: 0;
}
  
.hamburger-line.open:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}
  
.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--blue); 
    z-index: 50; 
    display: none;
    overflow-y: auto; 
}

.mobile-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.mobile-menu li a {
    text-decoration: none;
    color: white;
    font-size: 19px;
    display: block;
}

.hidden {
    display: none;
}

@media (min-width: 768px) {
  .mobile-menu {
    position: static;
    height: auto;
    display: block;
    background-color: transparent;
  }

  .mobile-menu ul {
    flex-direction: row;
    justify-content: flex-start;
    height: auto;
  }

  .mobile-menu li a {
    color: var(--blue);
  }
}
